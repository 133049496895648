<template>
  <b-modal
    scrollable
    centered
    hide-footer
    body-class="p-0"
    id="preview-modal"
    size="lg"
    :title="$t('sparkmembers.sales-page.info.column.modal-title')"
  >
    <div class="position-relative">
      <div class="position-absolute w-100 h-100" />
      <b-img-lazy v-bind="imageProps" :src="require('@/assets/images/SparkMembers/SalesPage/preview-sales-page.png')" />
    </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      imageProps: {
        center: true,
        fluidGrow: true,
        blank: true,
        blankColor: '#f4f4f4',
        height: 500,
      },
    };
  },
};
</script>
